import {TextIcon} from "../Icons";

const selectFieldOnFocus = (e) => {
    e.preventDefault();
    e.target.select();
};

const trimContentsOnPaste = (e) => {
    e.preventDefault();
    e.target.value = e.clipboardData.getData('text/plain').trim();
};

export default function TextField({fieldRef, defaultValue}) {
    return (
        <>
            <label
                htmlFor="highlight_text"
                className="block mb-2 font-medium text-gray-900 dark:text-white sr-only">
                Text to highlight
            </label>

            <div>
                <div className="flex w-full">
                    <span
                        className="inline-flex items-center px-3 text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      <TextIcon/>
                    </span>

                    <textarea
                        ref={fieldRef}
                        id="highlight_text"
                        rows="4"
                        required
                        className="rounded-r-lg min-h-18 bg-gray-50 border bg-gray-50 border border-gray-300 text-gray-900 text-baseline focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="The text to highlight on the page."
                        onFocus={selectFieldOnFocus}
                        onPaste={trimContentsOnPaste}
                        defaultValue={defaultValue}
                    />
                </div>
            </div>
        </>
    );
}