import './App.css';
import Info from './Info';
import LinkBuilder from './LinkBuilder/LinkBuilder';

function CreateLink({fWord}) {
    return (
        <div className="p-4">
            <div className="max-w-xl mx-auto">
                <header className="pb-12">
                    <h1 className="flex items-center text-3xl font-extrabold dark:text-gray-200 raleway-900 ">
                        <a href="/"><abbr title={`Read The ${fWord} Link`}>RTFL</abbr></a>
                    </h1>
                </header>
                <main>
                    <div className="mb-14 font-sans raleway-sb-600 text-4xl md:text-5xl dark:text-gray-200">
                        <div>Short links to get
                            <div
                                className="rounded-md highlighted-fuchsia bg-opacity-60 -mx-3 px-3 dark:text-gray-100 ">straight
                                to the point.</div>
                        </div>
                    </div>
                    <h2 className="sr-only">Link builder</h2>
                    <LinkBuilder fWord={fWord}/>
                </main>

                <div className="mt-10 inline-flex items-center justify-center w-full">
                    <hr className="w-64 h-px my-10 bg-gray-200 border-0 dark:bg-gray-700"/>
                    <span
                        className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">
            About <abbr className="raleway-900" title={`Read The ${fWord} Link`}>RTFL</abbr>
          </span>
                </div>

                <section>
                    <h2 className="sr-only">
                        About <abbr title={`Read The ${fWord} Link`}>RTFL</abbr>
                    </h2>

                    <Info>
                        <strong>What?</strong> Short links that jump to a specific point on (mostly) any webpage, with
                        the text you want highlighted.
                    </Info>

                    <Info>
                        <strong>How?</strong> Visit the link, copy the text, create the {fWord.toLowerCase()} link and
                        share.
                    </Info>

                    <Info>
                        <strong>Why?</strong> Someone
                        missed the main point? Something buried in the text? Share an RTFL link
                        to get straight to the point.
                    </Info>
                </section>
            </div>
        </div>
    );
}

export default CreateLink;
