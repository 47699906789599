import {useRef, useState} from 'react';

import LoadingSpinner from "./LoadingSpinner";

import {createLink} from './handlers';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import ReadabilityView from "./ReadabilityView";

import config from '../config';
import UrlField from "./UrlField";
import CreateButton from './CreateButton';
import ErrorAlert from "./ErrorAlert";
import CopyShortlink from "./CopyShortlink";

const fetcher = (setState, url, token) => {
    setState({data: null, error: false, isLoading: true})

    fetch(`${config.apiHost}/fetch`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            url: url,
            captcha_token: token,
        })
    }).then(res => {
        if (res.status !== 200) {
            throw new Error('An error occurred while fetching the data.');
        }
        return res.text()
    }).then((body) => {
        console.log('set good state')
        setState({data: body, error: false, isLoading: false});
    }).catch(() => {
        setState({data: null, error: true, isLoading: false});
    })
}

const paramsToInput = () => {
    let url;
    let text;

    let queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('url') && queryParams.has('text')) {
        url = queryParams.get('url');
        text = queryParams.get('text');
    } else {
        url = window.location.pathname.substring(1);
        queryParams.delete('url');
        queryParams.delete('text');

        if (queryParams.size > 0) {
            url += '?' + queryParams.toString();
        }
    }

    try {
        new URL(url);
    } catch (err) {
        url = '';
    }

    return {url, text}
}

function LinkBuilder({fWord}) {
    // Form references
    const formRef = useRef(null);
    const urlInput = useRef(null);
    const textInput = useRef(null);
    const shortlinkRef = useRef(null);

    // State
    const [createdLink, setCreatedLink] = useState(null);
    const [link, setLink] = useState('');
    const [errors, setErrors] = useState({});
    const [creating, setCreating] = useState();
    const [{data, error, isLoading}, setFetchState] = useState({data: null, error: false, isLoading: false})
    const [selectedText, setSelectedText] = useState('');
    const [notReadable, setNotReadable] = useState(false);

    // Effects
    const {executeRecaptcha} = useGoogleReCaptcha();

    // Callback
    // const handleReCaptchaVerify = useCallback(async () => {
    //     if (!executeRecaptcha) {
    //         return;
    //     }
    //
    //     try {
    //         const token = await executeRecaptcha('submit');
    //         setToken(token);
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }, [executeRecaptcha]);

    // Pull url from the url bar
    const {url} = paramsToInput();

    // Handlers
    const formSubmitHandler = (e) => {
        e.preventDefault();

        setCreating(true);
        setErrors({});

        executeRecaptcha('submit').then((token) => {
            return createLink(
                urlInput.current.value,
                textInput.current.value,
                token,
            )
        }).then((link) => {
            setCreating(false);
            setErrors({});
            setCreatedLink(link);
            window.scroll(0, 0)
        })
            .catch((err) => {
                setCreating(false);
                setErrors(err);
            });
    };

    const linkProvided = link.length > 0;
    const fetchComplete = (data || error) && !isLoading;
    const textProvided = selectedText && selectedText.length > 0;

    return (
        <form ref={formRef} onSubmit={formSubmitHandler}>

            {createdLink && (
                <>
                    <div className="rounded-lg p-5 -m-4 mb-3 bg-opacity-60 highlighted-lime">
                        <CopyShortlink shortlinkRef={shortlinkRef} shortlink={createdLink}/>
                    </div>
                    <div className="mt-3 text-center">
                        <a className="underline hover:no-underline text-grey-900 dark:text-gray-200" href="/">Start
                            again</a>
                    </div>
                </>
            )}

            {!createdLink &&
                <>
                    <UrlField
                        fieldRef={urlInput}
                        fetcher={fetcher.bind(null, setFetchState)}
                        setLink={setLink}
                        setSelected={setSelectedText}
                        errors={errors}
                        defaultUrl={url}
                        executeRecaptcha={executeRecaptcha}
                    />

                    {linkProvided && !fetchComplete &&
                        <LoadingSpinner/>
                    }

                    {linkProvided && fetchComplete &&
                        <div className="mt-5">
                            <span
                                className="font-medium text-gray-800 text-sm dark:text-white">Step 2. Copy your text</span>
                            <div className="mt-2">
                                <ReadabilityView content={data} errors={errors} textRef={textInput}
                                                 selectedText={selectedText} setSelectedText={setSelectedText}
                                                 setNotReadable={setNotReadable}
                                />
                            </div>
                        </div>
                    }

                    {(textProvided || notReadable) &&
                        <div className="sticky bottom-0">
                            {errors.type === 'global' && (
                                <ErrorAlert reason={errors.reason}/>
                            )}

                            <div
                                className="bg-opacity-80 bg-gray-50 dark:bg-transparent p-5 gap-2 flex items-center w-full">
                                <div className="w-full">
                                    {!createdLink && (
                                        <CreateButton fWord={fWord} isLoading={creating}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </form>
    );
}

export default LinkBuilder;
