import {useState} from "react";

export default function CopyShortlink({shortlinkRef, shortlink,}) {
    const [copying, setCopying] = useState(false);

    return (
        <>
            <label htmlFor="url-shortener" className="pb-5 font-medium text-gray-800 text-sm dark:text-white">Step 3.
                Copy your
                new link</label>
            <div className="flex items-center mt-2">

                <div className="relative w-full">
                    <input
                        id="url-shortener"
                        type="text"
                        aria-describedby="helper-text-explanation"
                        className="py-2.5 px-5 select-all border border-transparent select-all text-green-900 dark:text-white placeholder-green-700 dark:placeholder-green-500 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700"
                        ref={shortlinkRef}
                        defaultValue={shortlink}
                        readOnly
                        disabled
                    />
                </div>
                <button
                    className="py-2.5 px-5 ml-4 inline-flex items-center py-3 px-4 font-medium text-center text-gray-500 dark:text-gray-400 hover:text-gray-900 bg-gray-100 border border-gray-300 dark:border-transparent rounded-lg hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-white"
                    type="button"
                    onClick={async () => {
                        window.open(shortlink, '_blank').focus();
                    }}
                >
                <span
                    className={
                        `inline-flex items-center`
                    }>
                  <svg className="w-5 h-5 text-gray-500 dark:text-white" aria-hidden="true"
                       xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
</svg>

                </span>

                </button>
                <button
                    data-copy-to-clipboard-target="url-shortener"
                    className="py-2.5 px-5 ml-4 inline-flex items-center py-3 px-4 font-medium text-center text-gray-500 dark:text-white hover:text-gray-900 bg-gray-100 border border-gray-300 dark:border-transparent rounded-lg hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-white"
                    type="button"
                    onClick={async () => {
                        try {
                            await navigator.clipboard
                                .writeText(shortlinkRef.current.value)
                                .then(() => {
                                    setCopying(true);
                                    setTimeout(() => {
                                        setCopying(false);
                                    }, 1000);
                                });
                        } catch (err) {
                            console.log('Failed to copy');
                        }
                    }}>
                <span
                    className={
                        `inline-flex items-center` +
                        (copying === true ? ' hidden' : '')
                    }>
                  <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 18 20">
                    <path
                        d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                  </svg>
                </span>
                    <span
                        className={
                            `inline-flex items-center` +
                            (copying === true ? ' ' : ' hidden')
                        }>
                  <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12">
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                </span>
                </button>
                <div
                    id="tooltip-url-shortener"
                    role="tooltip"
                    className="absolute z-10 invisible inline-block py-2.5 px-5 font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    <span id="default-tooltip-message">Copy link</span>
                    <span id="success-tooltip-message" className="hidden">
                  Copied!
                </span>
                    <div className="tooltip-arrow"></div>
                </div>
            </div>
        </>
    )
}