import './App.css';
import {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import config from './config';
import {createTextFragment} from "./LinkBuilder/handlers";

const phrases = [
    'Breath in, breath out...',
    'Assume best intent...',
    'Just keep swimming...',
    'A friendly pointer...',
    "You're doing great...",
];

function View({fWord}) {
    const [error, setError] = useState(null);
    const [phraseIndex, setPhraseIndex] = useState(0);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [startTime] = useState(new Date().getTime());

    const urlLink = useRef(null);

    const {shortlink} = useParams();

    let invalidShortlink = false;
    if (!shortlink || shortlink.length < 1) {
        setError('Not found');
        invalidShortlink = true;
    }

    useEffect(() => {
        setPhraseIndex(Math.floor(Math.random() * phrases.length));
    }, []);

    useEffect(() => {
        if (invalidShortlink) return;
        if (redirectUrl) return;

        fetch(`${config.apiHost}/links/${shortlink}`)
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error('Error loading link');
                }
                return res;
            })
            .then((res) => res.json())
            .then((link) => {
                const url = new URL(link.url);

                setRedirectUrl({
                    url: url.origin + url.pathname,
                    full: link.url + '#:~:text=' + createTextFragment(link.text),
                    text: link.text,
                });

                const timeToLoad = new Date().getTime() - startTime;
                if (timeToLoad > 1500) {
                    urlLink.current.click();
                } else {
                    setTimeout(() => {
                        urlLink.current.click();
                    }, 1500 - timeToLoad);
                }
            })
            .catch(() => {
                setError('Unable to load link');
            });
    });

    return (
        <>

            {redirectUrl &&
                <Helmet>
                    <title>RTFL - Redirecting to {redirectUrl.url}</title>
                    <meta property="og:title" content={`Read The ${fWord} Link - Redirecting to ${redirectUrl.url}`}/>
                    <meta property="og:description" content={redirectUrl.text.substr(0, 500)}/>
                </Helmet>
            }
            {!redirectUrl &&
                <Helmet>
                    <title>RTFL - Loading...</title>
                </Helmet>
            }
            <div className="flex items-center justify-center h-dvh">
                <div className="max-w-md mx-auto">
                    <main>
                        <div className="text-gray-500 dark:text-gray-400 fade-in-up text-center text-wrap">
                            {!error && <div>{phrases[phraseIndex]}</div>}
                            <div className="text-sm mt-3">
                                {!redirectUrl && !error && (
                                    <div
                                        className="animate-pulse h-4 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[780px] mb-2.5"></div>
                                )}

                                {redirectUrl && !error && (
                                    <div>
                                        Heading to{' '}
                                        <a
                                            ref={urlLink}
                                            rel="noopener"
                                            href={redirectUrl.full}
                                            className="text-blue-600 underline dark:text-blue-500 hover:no-underline">
                                            {redirectUrl.url}
                                        </a>
                                    </div>
                                )}

                                {error && (
                                    <div className="tems-center">
                                        <div
                                            className="flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                                            role="alert">
                                            <svg
                                                className="flex-shrink-0 inline w-4 h-4 me-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 20 20">
                                                <path
                                                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                            </svg>
                                            <span className="sr-only">Info</span>
                                            <div>
                      <span className="font-medium">
                        We were unable to load your link.
                      </span>{' '}
                                                Please check it is valid and try again.
                                            </div>
                                        </div>
                                        <div>
                                            <a href="/">Return to homepage</a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}

export default View;
