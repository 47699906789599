import config from '../config';

export function visitLink(urlInput) {
    window.location.href = urlInput.current.value;
}

export function encodeTextCompany(text) {
    return encodeURIComponent(text)
        .replace('–', '%E2%80%93')
        .replace('—', '%E2%80%94');
}

export function createTextFragment(text) {
    const match = /[\r\n]/.exec(text);
    let start = text;
    let end = null;
    let usePartial = false;

    if (match) {
        const words = text.split(' ');
        start = words.slice(0, 5).join(' ');
        usePartial = true;
        end = words.slice(words.length - 5).join(' ');
    }

    let encodedText = encodeTextCompany(start);

    if (usePartial) {
        encodedText += ',' + encodeTextCompany(end);
    }

    return encodedText;
}

export function prepareUrl(url, text, endText, usePartial) {
    let go = url + '#:~:text=' + encodeTextCompany(text);

    if (usePartial) {
        go += ',' + encodeTextCompany(endText);
    }

    return go;
}

export function createLink(url, text, token) {
    return fetch(`${config.apiHost}/links`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            url: url,
            text: text,
            short_link_domain: window.location.origin,
            captcha_token: token,
        }),
    })
        .then((res) => res.json())
        .then((data) => {
            if (data.error_code) {
                if (data.field !== '') {
                    // eslint-disable-next-line no-throw-literal
                    throw {
                        type: 'field',
                        field: data.field,
                        reason: data.reason,
                    };
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw {
                        type: 'global',
                        reason:
                            'An error occurred when creating your link. Please try again.',
                    };
                }
            }

            return data.hostname + '/' + data.short_link;
        });
}
