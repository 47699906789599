import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {useState, useEffect} from 'react';

import CreateLink from './CreateLink';
import UseLink from './UseLink';

const fWords = [
    'Freaking',
    'Flipping',
    'Fudging',
    'Farking',
    'Frigging',
    'Flaming',
    'Forking',
    'Fricking', // Scrubs
    'Fraking', // BSG
    'Fecking', // Father Ted
    'Frelling', // Farscape
];

export default function App() {
    const [fWord, setFWord] = useState('');

    useEffect(() => {
        const index = Math.floor(Math.random() * fWords.length);
        setFWord(fWords[index]);
    }, []);

    return (
        <div className="bg-white dark:bg-gray-900 antialiased h-dvh h-screen">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<CreateLink fWord={fWord}/>}></Route>
                    <Route
                        path="/:shortlink"
                        element={<UseLink fWord={fWord}/>}></Route>
                    <Route path="/*" element={<CreateLink fWord={fWord}/>}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}
