function Info({children}) {
    return (
        <div
            className="mb-5 flex items-center w-full p-4 text-gray-900 bg-white rounded-lg shadow dark:text-gray-200 dark:bg-gray-800">
            <div className="ms-3 font-normal">
                <p
                    id="helper-text-explanation"
                    className="text-gray-500 dark:text-gray-200">
                    {children}
                </p>
            </div>
        </div>
    );
}

export default Info;
