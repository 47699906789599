import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleReCaptchaProvider
        reCaptchaKey="6Lc8GgAqAAAAAE8ZmaTwiIozd5E8C0gTXB9B579S"
        scriptProps={{
            async: true,
            defer: true,
        }}
    >
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </GoogleReCaptchaProvider>,
);

reportWebVitals();
