import {Readability, isProbablyReaderable} from '@mozilla/readability';
import {useMemo, useState} from 'react';
import DOMPurify from 'dompurify';
import TextField from "./TextField";

// A function that takes a copy event, and highlights the selected text across multiple
function onCopy(e, setSelected) {

    function removeHighlight(element) {
        const parent = element.parentNode;

        // Move all children of the element to be unwrapped to its parent node
        while (element.firstChild) {
            parent.insertBefore(element.firstChild, element);
        }

        // Remove the now empty element
        parent.removeChild(element);
    }

    function markToRemove(element) {
        element.setAttribute('data-remove', 'true');
    }

    function getNodesBetweenRange(range) {
        const startNode = range.startContainer;
        const endNode = range.endContainer;
        const commonAncestor = range.commonAncestorContainer;

        let nodes = [];
        let started = false;
        let finished = false;

        function traverseNodes(node) {
            if (finished) return;

            // If we are at a text node, include it if it falls within the range
            if (node.nodeType === Node.TEXT_NODE) {
                if (node === startNode) {
                    started = true;
                } else if (node === endNode) {
                    finished = true;
                    return;
                } else if (started) {
                    nodes.push(node);
                }
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                // Traverse child nodes
                for (let i = 0; i < node.childNodes.length; i++) {
                    traverseNodes(node.childNodes[i]);
                }
            }

            if (node === startNode) {
                started = true;
            } else if (node === endNode) {
                finished = true;
            }
        }

        traverseNodes(commonAncestor);

        return nodes;
    }

    // Function to highlight a text node
    function highlightTextNode(textNode, start, end, isStart, isEnd) {
        const span = document.createElement('mark');
        span.textContent = textNode.textContent.slice(start, end);
        span.className = 'bg-fuchsia-300 ';
        if (isStart) {
            span.classList.add('rounded-l-md');
        }
        if (isEnd) {
            span.classList.add('rounded-r-md');
        }

        const restTextBefore = document.createTextNode(textNode.textContent.slice(0, start));
        const restTextAfter = document.createTextNode(textNode.textContent.slice(end));

        const parentNode = textNode.parentNode;
        parentNode.insertBefore(restTextBefore, textNode);
        parentNode.insertBefore(span, textNode);
        parentNode.insertBefore(restTextAfter, textNode);

        parentNode.removeChild(textNode);
    }

    // Function to highlight the range
    function highlightRange(range) {
        const startContainer = range.startContainer;
        const endContainer = range.endContainer;

        // Check the order of nodes and swap if necessary
        if (startContainer.compareDocumentPosition(endContainer) & Node.DOCUMENT_POSITION_PRECEDING) {
            [range.startContainer, range.endContainer] = [range.endContainer, range.startContainer];
            [range.startOffset, range.endOffset] = [range.endOffset, range.startOffset];
        }

        if (startContainer === endContainer && startContainer.nodeType === Node.TEXT_NODE) {
            highlightTextNode(startContainer, range.startOffset, range.endOffset, true, true);
        } else {
            // Highlight start node
            if (startContainer.nodeType === Node.TEXT_NODE) {
                highlightTextNode(startContainer, range.startOffset, startContainer.textContent.length, true, false);
            }

            const nodes = getNodesBetweenRange(range);
            nodes.forEach(node => {
                if (node.nodeType === Node.TEXT_NODE) {
                    highlightTextNode(node, 0, node.textContent.length);
                }
            })

            // Highlight end node
            if (endContainer.nodeType === Node.TEXT_NODE) {
                highlightTextNode(endContainer, 0, range.endOffset, false, true);
            }

            if (window.getSelection().empty) {  // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {  // Firefox
                window.getSelection().removeAllRanges();
            }
        }
    }

    const selection = window.getSelection();

    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const selectedText = selection.toString();

    if (!selectedText) return;

    document.querySelectorAll('mark').forEach(mark => {
        markToRemove(mark);
    });

    setTimeout(() => {
        highlightRange(range);
        document.querySelectorAll('[data-remove="true"]').forEach(mark => {
            removeHighlight(mark);
        });
    }, 0);

    setSelected(selectedText);
}

function ReadabilityView({textRef, selectedText, setNotReadable, setSelectedText, content, errors}) {
    const [parsed, setParsed] = useState(null);
    const isReadable = parsed && parsed !== false && parsed !== null;


    useMemo(() => {
        const doc = new DOMParser().parseFromString(content, "text/html");

        // Remove any anchor tags from the document
        doc.querySelectorAll('a').forEach(link => {
            link.removeAttribute('href');
        });

        if (!isProbablyReaderable(doc)) {
            setParsed(null);
            setNotReadable(true);
            return;
        }
        let reader = new Readability(doc);
        setParsed(reader.parse());
        setNotReadable(false);
    }, [content, setNotReadable]);

    return (
        <>
            {!isReadable &&
                <div
                    className="flex mt-2 items-center p-3 mb-4 text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-red-400"
                    role="alert">
                    <span className="sr-only">Warning</span>
                    <div>The link you provided is either broken or doesn't have much in the way of content. Feel free to
                        provide the text
                        you want to highlight below instead.
                    </div>
                </div>
            }
            <div className={isReadable ? 'hidden' : ''}>
                <TextField fieldRef={textRef} defaultValue={selectedText}/>
                {errors.type === 'field' && errors.field === 'text' && (
                    <div
                        className="flex mt-5 items-center p-3 mb-4 text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                        role="alert">
                        <span className="sr-only">Error</span>
                        <div>Oops, {errors.reason}.</div>
                    </div>
                )}
            </div>
            {isReadable &&
                <div
                    className="prose prose-lg mx-auto p-5 bg-white dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg shadow"
                >
                    <div className="readability">
                        <h1>{parsed.title}</h1>
                        <div
                            onCopy={e => {
                                onCopy(e, setSelectedText);
                            }}
                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(parsed.content)}}></div>
                    </div>
                </div>
            }
        </>
    )
}

export default ReadabilityView;