import {LinksIcon} from "../Icons";
import ErrorAlert from "./ErrorAlert";

const addScheme = (e) => {
    if (
        e.target.value !== '' &&
        !e.target.value.startsWith('https://') &&
        !e.target.value.startsWith('http://')
    ) {
        e.target.value = 'https://' + e.target.value;
    }
};

const btnHandler = (ref, fetcher, setLink, setSelected, token, e) => {
    e.preventDefault();

    ref.current.reportValidity();

    if (ref.current.checkValidity()) {
        setLink(ref.current.value);
        setSelected('');
        fetcher(ref.current.value, token);
    }
};

export default function UrlField({defaultUrl, fieldRef, fetcher, setLink, setSelected, errors, executeRecaptcha}) {
    return (
        <div>
            <label htmlFor="url" className="pb-5 font-medium text-gray-800 text-sm dark:text-white">Step 1. Provide your
                link</label>
            <div className="mt-2 relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <LinksIcon/>
                </div>
                <input type="url" id="url"
                       ref={fieldRef}
                       onBlur={addScheme}
                       onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                               addScheme(e);
                           }
                       }}
                       defaultValue={defaultUrl}
                       className="block w-full p-4 ps-10 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder="Enter your link here"
                       required
                />

                <button
                    className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-4 py-2 dark:bg-blue-600 raleway-sb-600  dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="submit"
                    onClick={(e) => {
                        executeRecaptcha('submit').then(token => {
                            btnHandler(fieldRef, fetcher, setLink, setSelected, token, e);
                        });
                    }}
                >
                    Next
                </button>
            </div>
            {errors.type === 'field' && errors.field === 'url' && (
                <ErrorAlert reason={errors.reason}/>
            )}
        </div>
    )
}