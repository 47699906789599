import {ErrorIcon} from "../Icons";

export default function ErrorAlert({reason}) {
    return (<div
        className="flex items-center p-4 ext-red-800 rounded-t-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
        role="alert">
        <ErrorIcon/>

        <span className="sr-only">Error</span>
        <div>{reason}</div>
    </div>)
}